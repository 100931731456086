import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Anderson from "../assets/avatar/anderson.png";
import Marcelo from "../assets/avatar/marcelo.jpg";
import Gustavo from "../assets/avatar/gustavo.png";
import Robert from "../assets/avatar/robert.png";
import Vortex from "../assets/vortex.svg";
import { i18n } from "../translate/i18n";

const userTestimonials = [
  {
    avatar: <Avatar alt="Remy Sharp" src={Anderson} />,
    name: "Anderson Diniz",
    occupation: i18n.t("testimonials.position"),
    testimonial: i18n.t("testimonials.anderson"),
    linkedin: "https://www.linkedin.com/in/andersonldiniz/",
  },
  {
    avatar: <Avatar alt="Travis Howard" src={Robert} />,
    name: "Robert Ferreira",
    occupation: i18n.t("testimonials.position2"),
    testimonial: i18n.t("testimonials.robert"),
    linkedin: "https://www.linkedin.com/in/robert-ferreira-/",
  },
  {
    avatar: <Avatar alt="Cindy Baker" src={Gustavo} />,
    name: "Gustavo Oliveira",
    occupation: i18n.t("testimonials.position3"),
    testimonial: i18n.t("testimonials.gustavo"),
    linkedin: "https://www.linkedin.com/in/gustavo-florindo-oliveira/",
  },
  {
    avatar: <Avatar alt="Remy Sharp" src={Marcelo} />,
    name: "Marcelo Almeida",
    occupation: i18n.t("testimonials.position4"),
    testimonial: i18n.t("testimonials.marcelo"),
    linkedin: "https://www.linkedin.com/in/almeida-moreira/",
  },
];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

const logoStyle = {
  width: "60px",
  height: "60px",
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = Vortex;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {i18n.t("testimonials.title")}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {i18n.t("testimonials.desc")}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <IconButton
                  color="inherit"
                  href={testimonial.linkedin}
                  target="_blank"
                  aria-label="LinkedIn"
                  sx={{ alignSelf: "center" }}
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
